$(document).ready(function() {

    $(function () {
        $(document).scroll(function () {
        var $nav = $(".static-head");
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
        });
    });

});

